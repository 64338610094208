import React from "react";
import SliderLaptop from "../components/SliderLaptop";

export default function Banner() {
  return (
    <>
      <section className="banner">
        <picture>
          <img
            className="banner-glow-top animate-top-light"
            src="assets/front/images/banner-glow-top.svg"
            alt="images"
          />
        </picture>
        <div className="container">
          <div className="banner-content">
            <div className="heading-content">
              <h1 className="banner-heading">A fast market needs even</h1>

              <div className="two-parts">
                <h2 className="sentence">
                  <div className="slidingVertical">
                    <span>Better</span>
                    <span>Faster</span>
                    <span>Smarter</span>
                  </div>
                </h2>
                <h1 className="banner-heading b-2">analytics</h1>
              </div>
            </div>

            <p className="main-para">
              Ticketmetric collects data from over 120,000 events across <br />{" "}
              Ticketmaster, AXS, and StubHub markets to help ticket <br />{" "}
              brokers flip
              <span> better, faster, and smarter.</span>
            </p>

            <div className="btn-banner">
              <a
                href="https://buy.stripe.com/fZe01n8pa4XB5Mc4go"
                className="btn-main"
                style={{
                  marginBottom: "0px",
                }}
              >
                Sign Up
              </a>
              <span style={{ marginTop: "5px" }}>or</span>
              <a
                className="main-para"
                href="https://calendly.com/isaac-ticketmetric/tickemteric-demo"
                style={{
                  marginTop: "5px",

                  textDecoration: "underline",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = "#7c4dff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "#f2f2f2";
                }}
              >
                Schedule a Demo and a Free Trial
              </a>
              {/* <span>Free 3 day trial</span> */}
            </div>
          </div>
        </div>

        <div className="laptop-scene">
          <img
            className="stand"
            src="assets/front/images/stand.svg"
            alt="images"
          />

          <div className="laptop-lottie">
            <SliderLaptop />
          </div>

          <img
            className="shadow-image"
            src="assets/front/images/laptop-shadow.webp"
            alt="images"
          />
        </div>

        <img
          className="banner-glow-bottom animate-bottom-light"
          src="assets/front/images/banner-glow-bottom.svg"
          alt="images"
        />

        <div className="clip-path"></div>
      </section>
    </>
  );
}
