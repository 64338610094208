import React from "react";

export default function PriceBanner() {
  return (
    <>
      <section className="inner-banner" data-aos="fade-in">
        <img
          className="banner-glow-top animate-top-light"
          src="assets/front/images/banner-glow-top.png"
          alt=""
        />

        <div className="container b-content">
          <div className="row">
            <div
              className="col-lg-6 col-sm-12 b-left"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                className="logoBeta"
                src="assets/front/images/pricing/logo1.svg"
                alt=""
              />
              <h1>
                Get our launch plan for just $149/month (valued at $249/month)!
              </h1>
              <div className="btn-banner">
                <a
                  href="https://buy.stripe.com/fZe01n8pa4XB5Mc4go"
                  className="btn-main"
                >
                  Sign Up
                </a>
                {/* <span style={{ alignSelf: "center" }}>Free 3 day trial</span> */}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 b-right p-0">
              <div
                className="b-list"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  All Sold Data
                </span>
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  All Primary Historical Analytics
                </span>
              </div>
              <div
                className="b-list"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  Full Extension
                </span>
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  All Secondary Historical Analytics
                </span>
              </div>
              <div
                className="b-list m-0"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  Price Map shader
                </span>
                <span>
                  <img src="assets/front/images/pricing/check.png" alt="" />
                  20 Drop Alerts & 100 Low Alerts
                </span>
              </div>
            </div>
          </div>
        </div>

        <img
          className="banner-glow-bottom animate-bottom-light"
          src="assets/front/images/banner-glow-bottom.png"
          alt=""
        />
      </section>
    </>
  );
}
